import React from "react"
import AppStoreImg from "../components/images/AppStoreImg"
import LogoWithStageImg from "../components/images/LogoWithStageImg"
import MobileMockUp from "../components/images/MobileMockUp"
import PlaystoreImg from "../components/images/PlaystoreImg"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* Section 1 */}
    <div className="h-screen flex justify-center items-center -mt-20">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="grid">
          <div className="place-self-center">
            <div className="text-2xl sm:text-4xl md:text-5xl font-medium text-secondary grid justify-items-center">
              <p>Explore your</p>
              <p>talent with us.</p>
            </div>
            <div className="py-10 flex justify-center md:justify-items-start">
              <div
                className="cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.talsho.talsho",
                    "_blank"
                  )
                }
              >
                <PlaystoreImg />
              </div>
              <div className="cursor-pointer">
                <AppStoreImg />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <LogoWithStageImg />
        </div>
      </div>
    </div>
    {/* Section 2 */}
    <div className="h-screen flex justify-center items-center pb-40 mt-32 md:-mt-20">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex justify-center">
          <MobileMockUp />
        </div>
        <div className="flex items-center justify-center flex-col space-y-6 pt-20">
          <div className="flex justify-center items-center h-20 w-96 rounded-3xl shadow-xl bg-backgroundWhite text-secondary font-semibold text-xl md:text-2xl">
            Participate in the Events
          </div>
          <div className="flex justify-center items-center h-20 w-96 rounded-3xl shadow-xl bg-backgroundWhite text-secondary font-semibold text-xl md:text-2xl">
            Show your Talent
          </div>
          <div className="flex justify-center items-center h-20 w-96 rounded-3xl shadow-xl bg-backgroundWhite text-secondary font-semibold text-xl md:text-2xl">
            And win Cash Prizes
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
